.ruler-sidebar {
  position: fixed;
  left: 0;
  top: 0;
  width: 100px;
  height: 100vh;
  background: none;
  display: flex;
  align-items: center;
  z-index: 50;
}

.ruler-container {
  position: relative;
  height: 90%;
  width: 100%;
}

.ruler-line {
  position: absolute;
  left: 50%;
  top: 0;
  height: 100%;
  width: 0.5px;
  background-color: #9c9c9c;
  transform: translateX(-50%);
}

.ruler-marks {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ruler-mark {
  position: absolute;
  width: 100%;
  height: 24px;
  cursor: pointer;
}

.ruler-number {
  position: absolute;
  left: 14px;
  top: -12px;
  font-family: "Advent Pro", sans-serif;
  font-size: 14px;
  color: #9c9c9c;
  transition: color 0.2s ease;
}

.ruler-tick {
  position: absolute;
  left: 50%;
  top: 0;
  width: 18px;
  height: 1px;
  background-color: #9c9c9c;
  transform: translateX(-50%);
  transition: background-color 0.2s ease;
}

.ruler-indicator {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ruler-indicator-line {
  position: absolute;
  left: 50%;
  top: -16px;
  width: 5px;
  height: 32px;
  background-color: #ff2c2c;
  transform: translateX(-50%);
}

.ruler-indicator-dot {
  position: absolute;
  left: 50%;
  top: -5px;
  width: 8px;
  height: 8px;
  background-color: #ff8282;
  border-radius: 0;
  transform: translateX(-50%);
}

.ruler-mark:hover .ruler-number {
  color: #a1a1aa;
}

.ruler-mark:hover .ruler-tick {
  background-color: #52525b;
}

.ruler-mark.active .ruler-number {
  left: auto;
  right: 15px;
}

@keyframes labelSlideIn {
  from {
    opacity: 0;
    transform: translate(10px, -50%);
  }
  to {
    opacity: 1;
    transform: translate(0, -50%);
  }
}

.ruler-indicator {
  transition: top 0.3s ease;
}

.hamburger-menu {
  display: none;
}

.mobile-nav {
  display: none;
}

@media screen and (max-width: 1024px) {
  .ruler-container {
    display: none;
  }

  .hamburger-menu {
    display: block;
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 100;
    cursor: pointer;
    width: 30px;
    height: 24px;
  }

  .hamburger-line {
    width: 100%;
    height: 3px;
    background-color: #fff;
    position: absolute;
    left: 0;
    transition: 0.4s;
  }

  .hamburger-line:nth-child(1) {
    top: 0;
  }
  .hamburger-line:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }
  .hamburger-line:nth-child(3) {
    bottom: 0;
  }

  .hamburger-menu.open .hamburger-line:nth-child(1) {
    transform: rotate(45deg);
    top: 50%;
  }

  .hamburger-menu.open .hamburger-line:nth-child(2) {
    opacity: 0;
  }

  .hamburger-menu.open .hamburger-line:nth-child(3) {
    transform: rotate(-45deg);
    bottom: 50%;
  }

  .mobile-nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 40vh;
    width: 0;
    background: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    overflow-x: hidden;
    transition: 0.3s;
    padding-left: 60px;
    background: none;
  }

  .mobile-nav.open {
    width: 200px;
    background-color: #141414;
  }

  .mobile-nav-item {
    color: #fff;
    font-size: 24px;
    margin: 20px 0;
    text-decoration: none;
    transition: color 0.3s ease;
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
  }

  .mobile-nav.open .mobile-nav-item {
    opacity: 1;
    transform: translateX(0);
  }

  .mobile-nav-item:hover {
    color: #ff8282;
  }
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    top: 15px;
    left: 15px;
  }

  .mobile-nav-item {
    font-size: 22px;
  }
}

@media screen and (max-width: 480px) {
  .hamburger-menu {
    top: 20px;
    left: 20px;
    width: 25px;
    height: 20px;
  }

  .hamburger-line {
    height: 2px;
  }

  .mobile-nav-item {
    font-size: 20px;
  }

  .mobile-nav.open {
    width: 200px;
  }
}

@media screen and (max-width: 320px) {
  .hamburger-menu {
    top: 12px;
    left: 12px;
    width: 22px;
    height: 18px;
  }

  .mobile-nav-item {
    font-size: 18px;
  }

  .mobile-nav.open {
    width: 180px;
  }
}
