@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");

.services-page-container {
  display: flex;
  background-image: url("/public/images/services-bg.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.services-page-header {
  display: flex;
}

.page-services-title {
  display: flex;
  margin-left: 50px;
}

.page-services-title h1 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 64px !important;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  color: #bcbcbc;
  letter-spacing: 0.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100px;
}

.services-section-1,
.services-section-2,
.services-section-3,
.services-section-4,
.services-section-5,
.services-section-6 {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-services-content-1,
.main-services-content-2,
.main-services-content-3,
.main-services-content-4,
.main-services-content-5,
.main-services-content-6 {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
}

.services-1 h1,
.services-2 h1,
.services-3 h1,
.services-4 h1,
.services-5 h1,
.services-6 h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #9c9c9c;
}

.services-1 p,
.services-2 p,
.services-3 p,
.services-4 p,
.services-5 p,
.services-6 p {
  font-size: 14px;
  line-height: 1.8;
  color: #667085;
}

.services-1,
.services-2,
.services-3,
.services-4,
.services-5,
.services-6 {
  margin-top: 80px;
  width: 40%;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.services-1 img,
.services-2 img,
.services-3 img,
.services-4 img,
.services-5 img,
.services-6 img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: auto;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;
  animation: imageFloat 5s ease-in-out infinite;
}

@keyframes imageFloat {
  0%,
  100% {
    transform: translateY(0) scale(1);
  }
  25% {
    transform: translateY(-10px) scale(1.02) rotate(1deg);
  }
  75% {
    transform: translateY(10px) scale(1.02) rotate(-1deg);
  }
}

.services-1 img,
.services-2 img,
.services-6 img {
  width: 100%;
}

.services-3 img,
.services-4 img,
.services-5 img {
  width: 90%;
}

.services-1 img:hover,
.services-2 img:hover,
.services-3 img:hover,
.services-4 img:hover,
.services-5 img:hover,
.services-6 img:hover {
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.services-1,
.services-2,
.services-3,
.services-4,
.services-5,
.services-6 {
  animation: fadeIn 0.5s ease-out forwards;
}

.learn-more-btn {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.0581) 0%,
    rgba(255, 255, 255, 0.0308) 99.66%
  );
  color: #ffffff;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 12px;
  justify-self: end;
  letter-spacing: 0.1em;
  transition: all 0.3s ease;
  align-self: flex-start;
}

.learn-more-btn:hover {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.1) 0%,
    rgba(255, 255, 255, 0.05) 99.66%
  );
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.services-1,
.services-2,
.services-3,
.services-4,
.services-5,
.services-6 {
  margin-right: 100px;
  margin-top: 100px;
}

.services-footer {
  color: #98a2b3;
  font-size: 12px;
  margin-bottom: 50px;
  margin-top: 40px;
}

.sidebar-services {
  padding: 20px;
  color: #676767;
  gap: 1px;
}

.sidebar-services a {
  cursor: pointer;
  font-size: 12px;
  font-family: "Advent Pro", sans-serif;
  letter-spacing: 0.25em;
}

.sidebar-services a:hover {
  color: #ffffff;
  transform: scale(1.05);
}

.sidebar-services a.active-link {
  color: #ffffff;
}

@media screen and (min-width: 1920px) {
  .services-1 h1,
  .services-2 h1,
  .services-3 h1,
  .services-4 h1,
  .services-5 h1,
  .services-6 h1 {
    font-size: 46px;
  }

  .services-1 p,
  .services-2 p,
  .services-3 p,
  .services-4 p,
  .services-5 p,
  .services-6 p {
    font-size: 24px;
  }

  .sidebar-services a {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .services-page-container {
    flex-direction: column;
    justify-content: center;
  }

  .services-page-header {
    width: 100%;
    justify-content: center;
  }

  .page-services-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .services-page-container .services-page-header .page-services-title h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 50px !important;
    letter-spacing: 0.2em;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }

  .services-section-1,
  .services-section-2,
  .services-section-3,
  .services-section-4,
  .services-section-5,
  .services-section-6 {
    width: 100%;
    min-height: 50vh;
  }

  .main-services-content-1,
  .main-services-content-2,
  .main-services-content-3,
  .main-services-content-4,
  .main-services-content-5,
  .main-services-content-6 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .services-1,
  .services-2,
  .services-3,
  .services-4,
  .services-5,
  .services-6 {
    margin-right: 0;
  }

  .services-1 h1,
  .services-2 h1,
  .services-3 h1,
  .services-4 h1,
  .services-5 h1,
  .services-6 h1 {
    margin-top: -50px;
  }

  .services-1 img,
  .services-2 img,
  .services-3 img,
  .services-4 img,
  .services-5 img,
  .services-6 img {
    width: 70%;
    margin-top: -160px;
  }

  .services-footer {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .page-services-title h1 {
    font-size: 48px !important;
  }

  .services-1,
  .services-2,
  .services-3,
  .services-4,
  .services-5,
  .services-6 {
    width: 90%;
  }

  .services-1 h1,
  .services-2 h1,
  .services-3 h1,
  .services-4 h1,
  .services-5 h1,
  .services-6 h1 {
    font-size: 28px;
  }

  .services-1 p,
  .services-2 p,
  .services-3 p,
  .services-4 p,
  .services-5 p,
  .services-6 p {
    font-size: 12px;
  }

  .learn-more-btn {
    padding: 12px 16px;
    font-size: 10px;
  }

  .sidebar-services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .sidebar-services a {
    font-size: 10px;
  }
}

@media screen and (max-width: 480px) {
  .page-services-title h1 {
    font-size: 36px !important;
  }

  .services-1 h1,
  .services-2 h1,
  .services-3 h1,
  .services-4 h1,
  .services-5 h1,
  .services-6 h1 {
    font-size: 24px;
  }

  .learn-more-btn {
    padding: 10px 14px;
    font-size: 9px;
  }

  .sidebar-services a {
    font-size: 9px;
    letter-spacing: 0.1em;
  }
}

@media screen and (max-width: 320px) {
  .page-services-title h1 {
    font-size: 28px !important;
  }

  .services-1 h1,
  .services-2 h1,
  .services-3 h1,
  .services-4 h1,
  .services-5 h1,
  .services-6 h1 {
    font-size: 20px;
  }

  .services-1 p,
  .services-2 p,
  .services-3 p,
  .services-4 p,
  .services-5 p,
  .services-6 p {
    font-size: 11px;
  }

  .learn-more-btn {
    font-size: 8px;
  }

  .sidebar-services a {
    font-size: 8px;
  }
}
