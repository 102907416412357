@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");

.mobile-products-page {
  display: none;
}

@media (max-width: 1024px) {
  .mobile-products-page {
    display: block;
    background-color: #141414;
    color: #ffffff;
    padding: 2rem;
    margin-bottom: 20px;
  }

  .mobile-products-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 68px !important;
    font-family: "Advent Pro", sans-serif;
    font-weight: 500;
    color: #a4a4a4;
    letter-spacing: 0.2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }

  .mobile-products-subtitle {
    text-align: center;
    color: #9c9c9c;
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 0.9rem;
  }

  .product-cards {
    display: flex;
    flex-direction: column;
    gap: 50px;
  }

  .product-card {
    background-color: #d9d9d90d;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .product-info {
    flex: 1;
    padding-right: 15px;
  }

  .product-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
    color: #9e9e9e;
    letter-spacing: 0.1em;
  }

  .product-card p {
    font-size: 14px;
    color: #9c9c9c;
    margin-bottom: 15px;
  }

  .product-image {
    flex: 0 0 30%;
    max-width: 30%;
  }

  .product-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .mob-products-learn-more-btn {
    background: linear-gradient(
      88.3deg,
      rgba(255, 255, 255, 0.0581) 0%,
      rgba(255, 255, 255, 0.0308) 99.66%
    );
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 8px;
    font-size: 12px;
    justify-self: end;
    margin-top: 20px;
    letter-spacing: 0.1em;
  }
}

@media (max-width: 768px) {
  .mobile-products-title {
    font-size: 36px;
  }

  .mobile-products-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .mobile-service-card {
    height: auto;
  }
}

@media (max-width: 480px) {
  .mobile-products-title {
    font-size: 48px !important;
  }

  .mobile-products-subtitle {
    font-size: 0.8rem;
  }

  .mobile-service-card {
    padding: 1rem;
  }

  .mobile-service-title {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 320px) {
  .mobile-products-title {
    font-size: 28px;
  }
}
