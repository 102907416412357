.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
  padding-right: 100px;
  background: none;
  float: right !important;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
}

.header-logo {
  height: 80px;
  position: fixed;
}

@media screen and (max-width: 1024px) {
  .header {
    padding-right: 50px;
    padding-top: 30px;
  }

  .header-logo {
    height: 60px;
    top: 30px;
    right: 50px;
  }
}

@media screen and (max-width: 768px) {
  .header {
    padding-right: 30px;
    padding-top: 20px;
  }

  .header-logo {
    height: 50px;
    top: 20px;
    right: 30px;
  }
}

@media screen and (max-width: 480px) {
  .header {
    padding-right: 20px;
    padding-top: 15px;
  }

  .header-logo {
    height: 40px;
    top: 15px;
    right: 20px;
  }
}

@media screen and (max-width: 320px) {
  .header {
    padding-right: 15px;
    padding-top: 10px;
  }

  .header-logo {
    height: 35px;
    top: 10px;
    right: 15px;
  }
}
