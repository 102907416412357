@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");

.products-page-container {
  display: flex;
  position: relative;
  overflow: hidden;
}

.products-page-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/images/products-background.png"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/public/images/fedes-middle-background.png");
  background-repeat: no-repeat;
  background-size: cover, cover, 50%;
  background-position: center, center, center;
  filter: blur(1px);
  z-index: -1;
}

.products-page-container.examhub-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/images/examhub-bg.png"),
    linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/public/images/services-bg.png");
  background-repeat: no-repeat;
  background-size: 70%, cover, 100%;
  background-position: left, center, bottom;
}

.products-page-header {
  display: flex;
}

.page-products-title {
  display: flex;
  margin-left: 50px;
}

.page-products-title h1 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 64px !important;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  color: #bcbcbc;
  letter-spacing: 0.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100px;
}

.products-section {
  width: 100%;
  min-height: 100vh;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  justify-content: center;
}

.products-section-duplicate {
  width: 100%;
  min-height: 100vh;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 80px;
  justify-content: center;
}

.fedes-logo {
  display: flex;
  width: 50%;
  animation: bounce 2s infinite ease-in-out;
}

.examhub-logo {
  display: flex;
  width: 50%;
  animation: bounce 2s infinite ease-in-out;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}

.products-sub-content {
  color: darkgray;
  font-size: 14px;
  width: 70%;
  text-align: center;
  margin-top: 20px;
}

.products-sub-content img {
  margin-top: 30px;
}

.products-sub-content-eh {
  color: darkgray;
  font-size: 14px;
  width: 70%;
  text-align: center;
  margin-top: 20px;
  margin-left: -10%;
}

.products-sub-content-eh img {
  margin-top: 30px;
  width: 110%;
  margin-bottom: 40px;
}

.fedes-learn-more-btn {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.0581) 0%,
    rgba(255, 255, 255, 0.0308) 99.66%
  );
  color: #ffffff;
  border: none;
  padding: 15px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 12px;
  justify-self: end;
  margin-top: 20px;
  letter-spacing: 0.1em;
  margin-left: -100px;
}

.learn-more-btn {
  background: linear-gradient(
    88.3deg,
    rgba(255, 255, 255, 0.0581) 0%,
    rgba(255, 255, 255, 0.0308) 99.66%
  );
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 12px;
  justify-self: end;
  margin-top: 20px;
  letter-spacing: 0.1em;
}

.sidebar {
  padding: 20px;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #676767;
}

.sidebar a {
  cursor: pointer;
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 12px;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  letter-spacing: 0.8em;
}

.sidebar a:hover {
  color: #ffffff;
}

.sidebar a.active-link {
  color: #ffffff;
}

@media screen and (min-width: 1920px) {
  .products-sub-content p {
    font-size: 24px;
  }
  .products-sub-content-eh p {
    font-size: 24px;
  }
  .sidebar a {
    font-size: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .products-page-container {
    flex-direction: column;
    justify-content: center;
  }

  .products-page-header {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
  }

  .page-products-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .products-page-container .products-page-header .page-products-title h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 68px !important;
    letter-spacing: 0.2em;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 100px;
  }

  .products-section {
    min-height: auto;
  }

  .products-section-duplicate {
    margin-bottom: -200px;
  }

  .sidebar {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .products-page-container .products-page-header .page-products-title h1 {
    font-size: 58px !important;
  }
}

@media screen and (max-width: 480px) {
  .products-page-container .products-page-header .page-products-title h1 {
    font-size: 48px !important;
  }
}

@media screen and (max-width: 320px) {
  .products-page-container .products-page-header .page-products-title h1 {
    font-size: 36px !important;
  }
}
