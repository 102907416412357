@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");

.projects-page-container {
  display: flex;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/images/services-bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow-y: auto;
}

.projects-page-header {
  display: flex;
}

.page-projects-title {
  display: flex;
  margin-left: 50px;
}

.page-projects-title h1 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 64px !important;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  color: #bcbcbc;
  letter-spacing: 0.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100px;
}

.projects-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-projects-content {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.project-container {
  margin-top: 80px;
  width: 70%;
  margin-bottom: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-container h1 {
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #9c9c9c;
}

.project-container p {
  font-size: 14px;
  line-height: 1.8;
  color: #667085;
}

.project-container img {
  max-width: 70%;
  height: auto;
  display: block;
  margin: auto;
  object-fit: contain;
}

.project-container img:hover {
  transform: scale(1.05);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.project-container {
  animation: fadeIn 0.5s ease-out forwards;
}

.form-row-projects {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.form-group-projects {
  display: flex;
  gap: 15px;
}

.form-group-projects input {
  padding: 15px 10px;
  border: 1px solid #3d3d3d;
  border-radius: 10px;
  outline: none;
  font-size: 12px;
  background: none;
  color: #f1f1f1;
}

.request-service-btn {
  background-color: #3d3d3d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.request-service-btn:hover {
  background-color: #4d4d4d;
}

.projects-footer {
  color: #98a2b3;
  font-size: 12px;
  margin-left: -150px;
  margin-top: -120px;
}

.sidebar-projects {
  padding: 50px;
  color: #676767;
  gap: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar-projects a {
  cursor: pointer;
  font-size: 12px;
  font-family: "Advent Pro", sans-serif;
  letter-spacing: 0.25em;
}

.sidebar-projects a:hover {
  color: #ffffff;
  transform: scale(1.05);
}

.sidebar-projects a.active-link {
  color: #ffffff;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  text-align: center;
  width: 70%;
}

.empty-state h2 {
  font-size: 26px;
  color: #9c9c9c;
  margin-bottom: 1rem;
  font-family: "Advent Pro", sans-serif;
}

.empty-state p {
  color: #667085;
  margin-bottom: 2rem;
  font-size: 16px;
}

.back-button {
  background-color: #3d3d3d;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.back-button:hover {
  background-color: #4d4d4d;
}

.loading-state {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
}

.loading-state h2 {
  color: #9c9c9c;
  font-family: "Advent Pro", sans-serif;
  font-size: 24px;
}

@media screen and (min-width: 1920px) {
  .project-container {
    margin-left: 50px;
  }
  .project-container h1 {
    font-size: 46px;
  }

  .project-container p {
    font-size: 24px;
  }

  .project-container img {
    max-width: 90%;
  }

  .form-group-projects input {
    font-size: 22px;
  }
  .request-service-btn {
    font-size: 16px;
    width: 250px;
  }
  .sidebar-projects a {
    font-size: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .projects-page-container {
    flex-direction: column;
    justify-content: center;
  }

  .projects-page-header {
    width: 100%;
    justify-content: center;
  }

  .page-projects-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
    margin-top: 80px;
  }

  .page-projects-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .page-projects-title h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 50px !important;
    letter-spacing: 0.2em;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 50px;
  }

  .projects-section {
    width: 100%;
    min-height: 50vh;
  }
}
