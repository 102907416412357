@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");

.contact-page-container {
  display: flex;
  background-image: url("/public/images/contact-background.png");
  background-repeat: no-repeat;
  background-position: bottom;
}

.contact-page-header {
  display: flex;
}

.page-contact-title {
  display: flex;
  margin-left: 50px;
}

.page-contact-title h1 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 64px !important;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  color: #bcbcbc;
  letter-spacing: 0.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100px;
}

.contact-section {
  width: 100%;
  min-height: 100vh;
  margin-top: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-title {
  letter-spacing: 0.04em;
  text-align: center;
  line-height: 10px;
  margin-left: -100px;
}

.contact-title h1 {
  font-size: 42px;
  letter-spacing: 0.04em;
  text-align: center;
  color: #667085;
}

.contact-title h1 span {
  color: #141414;
  text-shadow: -1px 1px 0 #667085, 1px 1px 0 #667085, 1px -1px 0 #667085,
    -1px -1px 0 #667085;
  text-align: center;
}

.contact-title h5 {
  font-size: 15px;
  font-weight: 100;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: center;
  color: #ffffff;
}

.main-contact-content {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 20px;
}

.contact-form-container-1,
.contact-form-container-2 {
  margin-top: 80px;
  width: 40%;
  margin-bottom: 70px;
}

.contact-form-container-1 h1 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #667085;
}

.contact-form-container-1 p {
  font-size: 14px;
  line-height: 1.5;
  color: #667085;
  margin-bottom: 30px;
}

.contact-form-container-2 {
  margin-right: 100px;
  margin-top: 100px;
}

.contact-form {
  display: grid;
  gap: 20px;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  color: #bcbcbc;
  margin-bottom: 5px;
  font-size: 14px;
}

.form-group input,
.form-group textarea {
  background-color: #2c2c2c;
  border: 1px solid #444444;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.submit-btn {
  background: linear-gradient(
    90deg,
    rgba(164, 83, 83, 0.35) 0%,
    rgba(164, 83, 83, 0.35) 117.18%
  );
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: bold;
  border-radius: 4px;
  font-size: 16px;
  justify-self: end;
  width: 100%;
}

.contact-info {
  margin-top: 30px;
  font-size: 14px;
  color: #bcbcbc;
}

.contact-info p {
  margin: 0;
  line-height: 1.3;
}

.contact-info p:first-child {
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-info p:not(:first-child) {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.contact-info svg {
  margin-right: 8px;
}

.social-links {
  display: flex;
  gap: 15px;
  margin-top: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.social-links a {
  color: #5e5e5e;
  font-size: 28px;
  margin-top: 20px;
}

.contact-footer {
  color: #98a2b3;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 80px;
}

@media screen and (max-width: 1024px) {
  .main-contact-content {
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .contact-page-container {
    flex-direction: column;
    justify-content: center;
  }

  .contact-page-header {
    width: 100%;
    justify-content: center;
    margin-top: -40px;
  }

  .page-contact-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .page-contact-title h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 68px !important;
    letter-spacing: 0.2em;
    text-align: center;
    margin: 0;
    padding: 0;
    margin-top: 100px;
  }

  .contact-form-container-1,
  .contact-form-container-2 {
    width: 80%;
    margin-right: 0;
  }

  .contact-form-container-1 {
    margin-bottom: -30px;
  }

  .separate-bg-contact {
    background-image: url("/public/images/contact-new-bg.png");
    background-repeat: no-repeat;
    background-position: center;
  }

  .contact-section {
    margin-top: 20px;
  }

  .contact-info-title {
    display: none;
  }

  .contact-title {
    margin-left: 0;
  }

  .contact-title h5 {
    display: none;
  }

  .contact-footer {
    margin-bottom: 80px;
    margin: 20px;
    text-align: center;
  }

  .social-links {
    margin-bottom: -20px;
  }
}

@media screen and (max-width: 768px) {
  .page-contact-title {
    margin-left: 20px;
  }

  .page-contact-title h1 {
    font-size: 58px !important;
  }

  .contact-title h1 {
    font-size: 36px;
  }

  .contact-form-container-1,
  .contact-form-container-2 {
    width: 90%;
  }
}

@media screen and (max-width: 480px) {
  .page-contact-title h1 {
    font-size: 48px !important;
  }

  .contact-title h1 {
    font-size: 28px;
  }

  .contact-title h5 {
    font-size: 13px;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .contact-form-container-1,
  .contact-form-container-2 {
    width: 95%;
    margin-top: 40px;
  }
}

@media screen and (max-width: 320px) {
  .page-contact-title h1 {
    font-size: 36px !important;
  }

  .contact-title h1 {
    font-size: 24px;
  }

  .contact-title h5 {
    font-size: 12px;
  }

  .contact-form-container-1 h1 {
    font-size: 16px;
  }

  .contact-form-container-1 p {
    font-size: 12px;
  }

  .social-links a {
    font-size: 24px;
  }
}
