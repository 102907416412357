@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --item1-transform: translateX(-100%) translateY(-5%) scale(1.5);
  --item1-filter: blur(30px);
  --item1-zIndex: 11;
  --item1-opacity: 0;

  --item2-transform: translateX(0);
  --item2-filter: blur(0px);
  --item2-zIndex: 10;
  --item2-opacity: 1;

  --item3-transform: translate(50%, 10%) scale(0.8);
  --item3-filter: blur(10px);
  --item3-zIndex: 9;
  --item3-opacity: 1;

  --item4-transform: translate(90%, 20%) scale(0.5);
  --item4-filter: blur(30px);
  --item4-zIndex: 8;
  --item4-opacity: 1;

  --item5-transform: translate(120%, 30%) scale(0.3);
  --item5-filter: blur(40px);
  --item5-zIndex: 7;
  --item5-opacity: 0;
}

.hero-page-container {
  display: flex;
}

.page-header {
  display: flex;
}

.company-title {
  display: flex;
  margin-left: 50px;
}

.company-title h1 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 64px !important;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  color: #bcbcbc;
  letter-spacing: 0.4em;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
  width: 100px;
}

.hero-section {
  width: 100%;
  min-height: 100vh;
  margin-top: 50px;
}

.carousel {
  height: 70%;
  width: 100%;
  overflow: hidden;
}

.carousel .list {
  position: absolute;
  width: 75%;
  height: 80%;
  left: 50%;
  transform: translateX(-50%);
  margin-left: 120px;
}

.carousel .list .item {
  position: absolute;
  left: 0%;
  width: 50%;
  height: 100%;
  font-size: 15px;
  transition: all 1s ease-in-out;
}

.carousel .list .item img {
  width: 50%;
  position: absolute;
  left: 75%;
  top: 50%;
  transform: translateY(-50%);
  transition: right 1.5s;
}

.carousel .list .item .introduce {
  opacity: 0;
  pointer-events: none;
  color: "#9C9C9C";
}

.carousel .list .item:nth-child(2) .introduce {
  opacity: 1;
  pointer-events: auto;
  width: 400px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.5s;
}

.carousel .list .item:nth-child(2) .introduce .awards-list {
  opacity: 0;
  animation: showContent 0.5s 1.6s ease-in-out 1 forwards;
}

.carousel .list .item .introduce .title {
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
  color: #d4af37;
}

.carousel .list .item .introduce .topic {
  font-size: 40px;
  font-weight: 500;
  line-height: 50.4px;
}

.carousel .list .item .introduce .des {
  font-size: 14px;
  line-height: 22.5px;
  font-family: "Poppins" "sans-serif";
}

.carousel .list .item .introduce .awards-list {
  list-style-type: none;
  padding: 0;
  margin: 15px 0 0 0;
}

.carousel .list .item .introduce .awards-list li {
  font-size: 16px;
  margin: 0;
  position: relative;
}

.carousel .list .item .introduce .awards-list li::before {
  position: absolute;
  left: 0;
  color: #693eff;
}

.carousel .list .item:nth-child(1) {
  transform: var(--item1-transform);
  filter: var(--item1-filter);
  z-index: var(--item1-zIndex);
  opacity: var(--item1-opacity);
  pointer-events: none;
}

.carousel .list .item:nth-child(2) {
  z-index: 10;
  transform: translateX(0);
}

.carousel .list .item:nth-child(3) {
  transform: var(--item3-transform);
  filter: var(--item3-filter);
  z-index: var(--item3-zIndex);
}

.carousel .list .item:nth-child(4) {
  transform: var(--item4-transform);
  filter: var(--item4-filter);
  z-index: var(--item4-zIndex);
}

.carousel .list .item:nth-child(5) {
  transform: var(--item5-transform);
  filter: var(--item5-filter);
  opacity: var(--item5-opacity);
  pointer-events: none;
}

@keyframes showContent {
  from {
    transform: translateY(-30px);
    filter: blur(10px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
    filter: blur(0px);
  }
}

.carousel .list .item:nth-child(2) .introduce .title,
.carousel .list .item:nth-child(2) .introduce .topic,
.carousel .list .item:nth-child(2) .introduce .des {
  opacity: 0;
  animation: showContent 0.5s 1s ease-in-out 1 forwards;
}

.carousel .list .item:nth-child(2) .introduce .topic {
  animation-delay: 1.2s;
}

.carousel .list .item:nth-child(2) .introduce .des {
  animation-delay: 1.4s;
}

.carousel.next .item:nth-child(1) {
  animation: transformFromPosition2 0.5s ease-in-out 1 forwards;
}

.carousel.next .item:nth-child(2) {
  animation: transformFromPosition3 0.7s ease-in-out 1 forwards;
}

.carousel.next .item:nth-child(3) {
  animation: transformFromPosition4 0.9s ease-in-out 1 forwards;
}

.carousel.next .item:nth-child(4) {
  animation: transformFromPosition5 1.1s ease-in-out 1 forwards;
}

@keyframes transformFromPosition2 {
  from {
    transform: var(--item2-transform);
    filter: var(--item2-filter);
    opacity: var(--item2-opacity);
  }
  to {
    transform: var(--item1-transform);
    filter: var(--item1-filter);
    opacity: var(--item1-opacity);
  }
}

@keyframes transformFromPosition3 {
  from {
    transform: var(--item3-transform);
    filter: var(--item3-filter);
    opacity: var(--item3-opacity);
  }
  to {
    transform: var(--item2-transform);
    filter: var(--item2-filter);
    opacity: var(--item2-opacity);
  }
}

@keyframes transformFromPosition4 {
  from {
    transform: var(--item4-transform);
    filter: var(--item4-filter);
    opacity: var(--item4-opacity);
  }
  to {
    transform: var(--item3-transform);
    filter: var(--item3-filter);
    opacity: var(--item3-opacity);
  }
}

@keyframes transformFromPosition5 {
  from {
    transform: var(--item5-transform);
    filter: var(--item5-filter);
    opacity: var(--item5-opacity);
  }
  to {
    transform: var(--item4-transform);
    filter: var(--item4-filter);
    opacity: var(--item4-opacity);
  }
}

.carousel::before {
  width: 200px;
  height: 200px;
  content: "";
  background-image: linear-gradient(70deg, #e31d03, #7b027d);
  position: absolute;
  z-index: -1;
  border-radius: 20% 30% 80% 10%;
  filter: blur(100px);
  top: 150%;
  left: 55%;
  transform: translate(-10%, -50%);
  transition: 1s;
}

.carousel .list .item:nth-child(2)::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 100px;
  bottom: -150px;
  left: 75%;
  top: 90%;
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(130, 130, 130, 0) 100%
  );
  pointer-events: none;
  z-index: -1;
}

@media screen and (min-width: 1920px) {
  .carousel .list .item img {
    width: 60%;
  }
  .carousel .list .item .introduce .title {
    font-size: 45px;
    margin-bottom: 20px;
  }

  .carousel .list .item .introduce .topic {
    font-size: 50px;
    margin-bottom: 20px;
  }

  .carousel .list .item .introduce .des {
    font-size: 24px;
  }

  .carousel .list .item .introduce .awards-list li {
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) {
  .hero-page-container {
    flex-direction: column;
    justify-content: center;
  }

  .page-header {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
  }

  .company-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .hero-page-container .page-header .company-title h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 68px !important;
    letter-spacing: 0.2em;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .hero-section {
    min-height: auto;
    margin-bottom: 100px;
  }

  .carousel {
    height: auto;
    margin-top: 2rem;
  }

  .carousel .list {
    width: 90%;
    height: auto;
    margin-left: 0;
    position: relative;
  }

  .carousel .list .item {
    width: 100%;
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel .list .item img {
    width: 40% !important;
    position: relative;
    left: 0;
    top: 0;
    transform: none;
    margin: 0 auto 1rem;
  }

  .carousel .list .item .introduce,
  .carousel .list .item:nth-child(2) .introduce {
    width: 100%;
    position: relative;
    top: 0;
    transform: none;
    text-align: center;
    padding: 0 1rem;
    opacity: 1;
  }

  .carousel .list .item .introduce .title {
    font-size: 18px;
    line-height: 28px;
  }

  .carousel .list .item .introduce .topic {
    font-size: 32px;
    line-height: 40px;
  }

  .carousel .list .item .introduce .des {
    font-size: 14px;
    line-height: 20px;
    margin: 1rem 0;
  }

  .carousel::before {
    width: 150px;
    height: 150px;
    left: 50%;
    top: 135%;
    transform: translate(-50%, -50%);
  }

  .carousel .list .item:nth-child(1),
  .carousel .list .item:nth-child(3),
  .carousel .list .item:nth-child(4),
  .carousel .list .item:nth-child(5) {
    display: none;
  }

  .carousel .list .item:nth-child(2) {
    transform: none;
    opacity: 1;
    filter: none;
  }

  @keyframes showContent {
    to {
      opacity: 1;
      transform: none;
      filter: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .hero-page-container .page-header .company-title h1 {
    font-size: 58px !important;
  }

  .hero-page-container .page-header .company-title h1 span {
    font-size: 20px !important;
  }

  .carousel .list .item img {
    width: 60% !important;
  }

  .carousel .list .item .introduce .topic {
    font-size: 28px;
    line-height: 36px;
  }

  .carousel::before {
    top: 148%;
  }
}

@media screen and (max-width: 480px) {
  .hero-page-container .page-header .company-title h1 {
    font-size: 48px !important;
  }

  .hero-page-container .page-header .company-title h1 span {
    font-size: 16px !important;
  }

  .carousel .list .item img {
    width: 80% !important;
  }

  .carousel .list .item .introduce .title {
    font-size: 16px;
    line-height: 24px;
  }

  .carousel .list .item .introduce .topic {
    font-size: 24px;
    line-height: 30px;
  }

  .carousel .list .item .introduce .des {
    font-size: 12px;
    line-height: 18px;
  }

  .carousel::before {
    top: 180%;
  }
}

@media screen and (max-width: 320px) {
  .hero-page-container .page-header .company-title h1 {
    font-size: 36px !important;
  }

  .hero-page-container .page-header .company-title h1 span {
    font-size: 12px !important;
  }

  .carousel .list .item .introduce .topic {
    font-size: 20px;
    line-height: 26px;
  }

  .partners-container {
    grid-template-columns: 1fr;
  }
}
