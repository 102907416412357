@media (max-width: 1024px) {
  .services-page {
    display: none;
  }
}

@media (min-width: 1025px) {
  .mobile-services-page {
    display: none;
  }
}
