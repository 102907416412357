@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");

.mobile-services-page {
  display: none;
}

@media (max-width: 1024px) {
  .mobile-services-page {
    display: block;
    background-color: #141414;
    color: #ffffff;
    padding: 2rem;
  }

  .mobile-services-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 0.5rem;
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 68px !important;
    font-family: "Advent Pro", sans-serif;
    font-weight: 500;
    color: #a4a4a4;
    letter-spacing: 0.2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
  }

  .mobile-services-subtitle {
    text-align: center;
    color: #9c9c9c;
    margin-top: 20px;
    margin-bottom: 50px;
    font-size: 0.9rem;
  }

  .mobile-services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.5rem;
    max-width: 900px;
    margin: 0 auto;
  }

  .mobile-service-card {
    background-color: #1e1e1e;
    border-radius: 10px;
    padding: 1rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .mobile-service-icon-container {
    margin-bottom: 1rem;
  }

  .mobile-service-icon {
    width: 40%;
    float: right;
  }

  .mobile-service-title {
    font-size: 0.9rem;
    color: #9c9c9c;
  }

  .form-row-mob-services {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }
  .form-group-mob-services {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }
  .form-group-mob-services input {
    padding: 15px 10px;
    border: 1px solid #3d3d3d;
    border-radius: 10px;
    outline: none;
    font-size: 12px;
    background: none;
    color: #f1f1f1;
    width: 100%;
    box-sizing: border-box;
  }
  .mob-services-request-service-btn {
    background-color: #3d3d3d;
    color: white;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
    margin-bottom: 40px;
  }

  .mob-services-request-service-btn:hover {
    background-color: #4d4d4d;
  }

  @media (max-width: 768px) {
    .mobile-services-title {
      font-size: 36px;
    }

    .mobile-services-grid {
      grid-template-columns: repeat(2, 1fr);
    }

    .mobile-service-card {
      height: auto;
    }
  }

  @media (max-width: 480px) {
    .mobile-services-title {
      font-size: 48px !important;
    }

    .mobile-services-subtitle {
      font-size: 0.8rem;
    }

    .mobile-service-card {
      padding: 1rem;
    }

    .mobile-service-title {
      font-size: 0.8rem;
    }
  }

  @media screen and (max-width: 320px) {
    .mobile-services-title {
      font-size: 28px;
    }
  }
}
