@import url("https://fonts.googleapis.com/css2?family=Advent+Pro:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arima:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.landing-page-container {
  display: flex;
  background-image: url("/public/images/landing-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  top: 0;
  overflow-x: hidden;
}

.landing-page-header {
  display: flex;
}

.landing-company-title {
  display: flex;
  margin-left: 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -120px;
}

.landing-company-title h1 {
  writing-mode: vertical-rl;
  transform: rotate(180deg);
  font-size: 64px !important;
  font-family: "Advent Pro", sans-serif;
  font-weight: 500;
  color: #bcbcbc;
  letter-spacing: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.landing-company-title h1 span {
  font-size: 18px;
  letter-spacing: 0.8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 400;
}

.landing-section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-left: -100px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.landing-section h1.animated-title {
  font-family: "Arima", sans-serif !important;
  animation: fadeInUp 1s ease forwards;
  opacity: 0;
  display: inline-block;
  font-size: 60px;
  font-weight: bolder;
  line-height: 36px;
  color: #ffffff;
  width: 80%;
  margin-top: 150px;
}

.landing-section p.landing-title {
  font-size: 20px;
  color: #d4af37;
  width: 80%;
  margin-top: 0px;
  font-weight: 500;
}

.landing-section p.landing-description {
  font-size: 18px;
  color: #ffffff;
  width: 60% !important;
  margin-top: 20px;
  width: 70%;
}

.partners-section {
  width: 90%;
  padding: 60px 0;
}

.partners-container {
  max-width: 92%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-top: 80px;
}

.partner-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease;
}

.partner-item:hover {
  transform: scale(1.2);
}

.partner-image {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  filter: grayscale(0%);
  opacity: 0.7;
  transition: all 0.3s ease;
  width: 110px;
}

@media screen and (min-width: 1920px) {
  .landing-section h1.animated-title {
    font-size: 74px;
  }

  .landing-section p.landing-title {
    font-size: 34px;
  }

  .landing-section p.landing-description {
    font-size: 30px;
  }
}

@media screen and (max-width: 1024px) {
  .landing-page-container {
    flex-direction: column;
    justify-content: center;
  }

  .landing-page-header {
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
  }

  .landing-company-title {
    margin-left: 0;
    width: 100%;
    justify-content: center;
  }

  .landing-page-container .landing-page-header .landing-company-title h1 {
    writing-mode: horizontal-tb;
    transform: none;
    font-size: 68px !important;
    letter-spacing: 0.2em;
    text-align: center;
    margin: 0;
    padding: 0;
  }

  .landing-page-container .landing-page-header .landing-company-title h1 span {
    font-size: 24px !important;
    letter-spacing: 0.4em;
    margin-top: 0.5rem;
  }

  .landing-section {
    min-height: auto;
    margin-bottom: 100px;
    text-align: center;
    margin-left: 0;
  }

  .animated-title {
    font-size: 36px !important;
  }

  .partners-section {
    padding: 30px 0;
  }

  .partners-container {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 0 15px;
  }

  .partner-item {
    width: 100%;
    padding: 10px;
  }

  .partner-image {
    max-width: 80%;
    max-height: 40px;
    width: auto;
  }
}

@media screen and (max-width: 768px) {
  .landing-page-container .landing-page-header .landing-company-title h1 {
    font-size: 58px !important;
  }
}

@media screen and (max-width: 480px) {
  .landing-page-container .landing-page-header .landing-company-title h1 {
    font-size: 48px !important;
  }

  .partners-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .partner-image {
    max-height: 30px;
  }
}

@media screen and (max-width: 320px) {
  .landing-page-container .landing-page-header .landing-company-title h1 {
    font-size: 36px !important;
  }
}
